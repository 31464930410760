import {
  GET_CATEGORIES, GET_PRODUCTS, GET_PRODUCT_DETAILS,
  LOAD_PRODUCTS, PRODUCTS_FAILED, PROD_CLEAR_INDS
} from 'container/types';

export const indicators = { loading: false, };


export const defaultProducts = { offers: [], ...indicators, categories: [] };

export default (state = defaultProducts, { type, payload }) => {
  switch (type) {
    case LOAD_PRODUCTS:
      return { ...state, loading: !state.offers.length };
    case PROD_CLEAR_INDS:
      return { ...state, message: undefined, step: undefined };
    case GET_PRODUCTS:
      return { ...state, loading: false, ...payload };
    case GET_CATEGORIES:
      return { ...state, categories: payload };
    case PRODUCTS_FAILED:
      return { ...state, loading: false };
    case GET_PRODUCT_DETAILS:
      return { ...state, offers: payload };
    default:
      return state;
  }
};
