import { ConnectedRouter } from 'connected-react-router';
import Layout from 'layouts';
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { path } from './constants';

const routes = [
  {
    path: path.HOMEPAGE,
    Component: lazy(() => import('pages/landing')),
    exact: true,
  },
  {
    path: path.SCHEDULE,
    Component: lazy(() => import('pages/agents')),
    exact: true,
  },
  {
    path: path.PLANS,
    Component: lazy(() => import('pages/app/plans')),
    exact: true,
  },
  {
    path: path.TERMS,
    Component: lazy(() => import('pages/app/terms&cond')),
    exact: true,
  },
  {
    path: path.CATALOG,
    Component: lazy(() => import('pages/app/catalog')),
    exact: true,
  },
  {
    path: path.DETAILS,
    Component: lazy(() => import('pages/app/details')),
    exact: true,
  },
  {
    path: path.STORES,
    Component: lazy(() => import('pages/app/stores')),
    exact: true,
  },
  // AUTH PAGES
  {
    path: path.LOGIN,
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: path.LOGIN_AGENT,
    Component: lazy(() => import('pages/agents/login')),
    exact: true,
  },
  {
    path: path.KYC,
    Component: lazy(() => import('pages/app/KYC')),
    exact: true,
  },
  {
    path: path.DASHBOARD_AGENT,
    Component: lazy(() => import('pages/agents/dashboard')),
    exact: true,
  },
  {
    path: path.AGENT_USERS,
    Component: lazy(() => import('pages/agents/Users')),
    exact: true,
  },
  {
    path: path.AGENT_USER_CREATE,
    Component: lazy(() => import('pages/agents/UserCreate')),
    exact: true,
  },
  {
    path: path[404],
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
];

const Router = ({ history }) => {
  const { routerAnimation } = useSelector(({ settings }) => settings);
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {routes.map(({ path: p, Component, exact }) => (
                      <Route
                        path={p}
                        key={p}
                        exact={exact}
                        render={() => (
                          <div className={routerAnimation}>
                            <Suspense fallback={<div>Loading...</div>}>
                              <Component />
                            </Suspense>
                          </div>
                        )}
                      />
                    ))}
                    <Redirect to={path[404]} />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default Router;
