import NProgress from 'nprogress';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { path } from '../constants';
import AuthLayout from './Auth';
import MainLayout from './Main';
import { isLoggedIn } from '../utils';

const Layouts = { auth: AuthLayout, main: MainLayout };

let previousPath = '';

const Layout = ({ children, location: { pathname, search } }) => {
  const { loading } = useSelector(({ user }) => user);
  const authorized = isLoggedIn();
  const getLayout = () => (new RegExp('auth').test(pathname) ? 'auth' : 'main');
  //
  if (pathname === path.SUPPORT_AGENT) {
    window.location = path.LOGIN_AGENT;
  }

  const Container = Layouts[getLayout()];
  const isAuthLayout = getLayout() === 'auth';

  // NProgress & ScrollTop Management
  const currentPath = pathname + search;
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0);
    NProgress.start();
  }
  setTimeout(() => {
    NProgress.done();
    previousPath = currentPath;
  }, 300);

  const BootstrappedLayout = () => {
    if (loading && !authorized && !isAuthLayout) return null;
    // redirect to landing page if current is not an auth page and user not authorized
    if (!isAuthLayout && !authorized && pathname === path.HOMEPAGE)
      return <Container>{children}</Container>;
    // redirect to landing page if current is not login page and user not authorized
    if (!isAuthLayout && !authorized) return <Redirect to={path.HOMEPAGE} />;
    // in other case render previously set layout
    return <Container>{children}</Container>;
  };

  return (
    <Fragment>
      <Helmet titleTemplate='SelfCare | %s' />
      {BootstrappedLayout()}
    </Fragment>
  );
};

export default withRouter(Layout);
