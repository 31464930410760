import {
  AGENT_CLIENT_DETAILS,
  AGENT_CLIENT_ERROR,
  AGENT_CLIENT_PROFILE,
  AGENT_CLIENT_PROFILE_LOADING,
  AGENT_CLIENT_LOANS,
  AGENT_LOADING,
  AGENT_LOGIN,
  AGENT_LOGOUT,
  AGENT_GET_ALL_USERS,
  AGENT_CREATE_USERS,
  AGENT_EXTEND_DEVICE,
  AGENT_GET_COMMENTS,
  AGENT_POST_COMMENT,
} from '../types';

const agentUser = localStorage.getItem('agentUser');

const initials = {
  agent: agentUser ? JSON.parse(agentUser) : null,
  agentLoading: false,
  agentToken: localStorage.getItem('accessToken') ?? null,
  agentError: null,
  clientDetails: null,
  clientLoans: null,
  clientImage: null,
  clientImageLoading: null,
  agentComments: null,
  agentPostComment: null,
  agentExtendDevice: null,
  agentCreateUser: null,
  agentGetUsers: null,
};

export default (state = initials, { type, payload }) => {
  switch (type) {
    case AGENT_LOGIN:
      return {
        ...state,
        agent: agentUser ? JSON.parse(agentUser) : null,
        agentToken: localStorage.getItem('accessToken'),
      };
    case AGENT_CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: payload,
      };
    case AGENT_CLIENT_LOANS:
      return {
        ...state,
        clientLoans: payload,
      };
    case AGENT_CLIENT_ERROR:
      return {
        ...state,
        agentError: payload,
      };
    case AGENT_LOADING:
      return {
        ...state,
        agentLoading: payload,
      };
    case AGENT_CLIENT_PROFILE_LOADING:
      return {
        ...state,
        clientImageLoading: payload,
      };
    case AGENT_CLIENT_PROFILE:
      return {
        ...state,
        clientImage: payload,
      };
    case AGENT_GET_ALL_USERS:
      return {
        ...state,
        agentGetUsers: payload,
      }
    case AGENT_CREATE_USERS:
      return {
        ...state,
        agentCreateUser: payload,
      }
    case AGENT_EXTEND_DEVICE:
      return {
        ...state,
        agentExtendDevice: payload,
      }
    case AGENT_GET_COMMENTS:
      return {
        ...state,
        agentComments: payload,
      }
    case AGENT_POST_COMMENT:
      return {
        ...state,
        agentPostComment: payload,
      }
    case AGENT_LOGOUT:
      return initials;
    default:
      return {
        ...state,
      };
  }
}
