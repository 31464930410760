/* eslint-disable no-bitwise */
import { store } from 'container';
import { UPDATE_CART } from 'container/types';
import { createBrowserHistory } from 'history';
import { useLayoutEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import engKiny from 'constants/language';
import { string } from 'prop-types';

export const history = createBrowserHistory();

export const useWindowSize = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    const updateSize = () => {
      const { innerWidth: width, innerHeight: height } = window;
      setSize({ width, height });
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const numberDelimiter = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const genNumber = (length = 1, upper = 256) =>
  Array.from({ length }, () => Math.floor(Math.random() * upper));

const toHEX = ([red = 255, green = 255, blue = 255]) =>
  `#${((red << 16) + (green << 8) + blue).toString(16).padStart(6, '0')}`;

const toRGBA = ([red = 255, green = 255, blue = 255], opacity = 1) =>
  `rgba(${red}, ${green}, ${blue}, ${opacity})`;

const calcTint = (value, index = 0.0001) => Math.round(value + (255 - value) * index);

const calcShade = (value, index = 0.4) => Math.round(value * index);

export const genColor = (type = 'solo', format = 'hex', opacity = 1) => {
  // type can be oneOf(['solo' || 'tint' || 'shade' || 'soloTint' || 'soloShade' || 'tintShade' || 'all'])
  // format can oneOf(['hex', 'rgba'])
  // opacity can be oneOf([number, array[numbers]])
  const [red, green, blue] = genNumber(3); // 0 - 255

  const formatFn = (array, darkness) =>
    format === 'rgba' ? toRGBA(array, darkness) : toHEX(array);

  const solo = [red, green, blue];
  const tint = [calcTint(red), calcTint(green), calcTint(blue)];
  const shade = [calcShade(red), calcShade(green), calcShade(blue)];
  let solOP, tintOP, shadeOP;
  if (typeof opacity === 'number') {
    solOP = opacity;
    tintOP = opacity;
    shadeOP = opacity;
  } else if (typeof opacity === 'object' && opacity.length) {
    [solOP, tintOP, shadeOP] = opacity;
  }

  switch (type) {
    case 'solo':
      return formatFn(solo, solOP);
    case 'tint':
      return formatFn(tint, tintOP);
    case 'shade':
      return formatFn(shade, shadeOP);
    case 'soloTint':
      return [formatFn(solo, solOP), formatFn(tint, tintOP)];
    case 'soloShade':
      return [formatFn(solo, solOP), formatFn(shade, shadeOP)];
    case 'tintShade':
      return [formatFn(tint, tintOP), formatFn(shade, shadeOP)];
    case 'all':
      return [formatFn(solo, solOP), formatFn(tint, tintOP), formatFn(shade, shadeOP)];
    default:
      return formatFn(solo, solOP);
  }
};

export const lookUpCart = item => {
  const { specs: specifications = {} } = item;
  const { items: cart } = store.getState().cart;
  const results = cart.find(({ specs }) => specs?.deviceTypeId === specifications?.deviceTypeId);
  return results;
};

export const adjustCart = item => {
  const { specs: specifications } = item;
  const { items: cart } = store.getState().cart;
  if (lookUpCart(item))
    store.dispatch({
      type: UPDATE_CART,
      payload: cart.filter(({ specs }) => specs.deviceTypeId !== specifications.deviceTypeId),
    });
  else store.dispatch({ type: UPDATE_CART, payload: [...cart, { ...item, quantity: 1 }] });
};

export const updateQuantity = (item, quantity) => {
  const { items: cart } = store.getState().cart;
  const built = it => (it === item ? { ...it, quantity: quantity >= 1 ? quantity : 1 } : it);
  store.dispatch({ type: UPDATE_CART, payload: cart.map(built) });
};

export const calcRepayment = () => {
  const { items: cart } = store.getState().cart;
  return cart.reduce(
    (prev, { quantity, plans: [first = {}] }) => {
      const { monthlyPayment, weeklyPayment, dailyPayment } = first;
      return {
        monthly: prev.monthly + monthlyPayment * quantity,
        weekly: prev.weekly + weeklyPayment * quantity,
        daily: prev.daily + dailyPayment * quantity,
      };
    },
    { monthly: 0, weekly: 0, daily: 0 }
  );
};

export const calcTenure = () => {
  const { items: cart } = store.getState().cart;
  return cart.reduce((prev, { plans: [first = {}] }) => Math.max(prev, first?.periodicity), 0);
};

export const sumCart = () => {
  const { items: cart } = store.getState().cart;
  const { quantity: sum } = cart.reduce(
    (prev, curr) => {
      prev.quantity += curr.quantity;
      return prev;
    },
    { quantity: 0 }
  );
  return sum;
};
export const trPlan = (name_plan, lang) => {
  const { PLAN_FAMILY, PLAN_PERSONAL, PERSONAL_PLAN, FAMILY_PLAN } = engKiny(lang);
  return name_plan === "Family" ? FAMILY_PLAN : name_plan === "Personal" && PERSONAL_PLAN;
};
export const addressLabel = (name, lang) => {
  const { KYC_PROVINCE, KYC_DISTRICT, KYC_SECTOR, KYC_CELL, KYC_VILLAGE } = engKiny(lang);
  const text = String(name).toLocaleLowerCase().toString();
  switch (text) {
    case 'province':
      return KYC_PROVINCE;
    case 'district':
      return KYC_DISTRICT;
    case 'sector':
      return KYC_SECTOR;
    case 'cell':
      return KYC_CELL;
    case 'village':
      return KYC_VILLAGE;
    default:
      return KYC_VILLAGE;
  }
};
export const ordial = number => {
  const j = number % 10;
  const k = number % 100;
  if (j === 1 && k !== 11) return `${number}st`;
  if (j === 2 && k !== 12) return `${number}nd`;
  if (j === 3 && k !== 13) return `${number}rd`;
  return `${number}th`;
};

export const unfoldCart = cart =>
  cart
    .map(({ quantity: length, ...item }) =>
      length < 1 ? item : Array.from({ length }, () => item)
    )
    .flat();

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(img);
  reader.onloadend = callback;
};

export const getToken = () => {
  return localStorage.getItem('accessToken');
};
export const getLang = () => {
  return localStorage.getItem('s_lng');
};
export const setLang = lng => {
  localStorage.setItem('s_lng', lng);
};
export const isPF=()=>{
  return localStorage.getItem('ispf');
}
export const setPf=(pf)=>{
  localStorage.setItem('ispf', pf);
}
export const setToken = token => {
  return localStorage.setItem('accessToken', token);
};

export const isLoggedIn = () => {
  const token = getToken();
  const currentDate = new Date();
  if (token) {
    const decodedToken = decodeToken();
    if (decodedToken) {
      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.clear();
        localStorage.removeItem('userToken');
        return false;
      }
    }
    return !!decodedToken;
  }
  return false;
};
export const findPlanDesc = (data, selected) => {
  const variabel = data.find(item => item.name === selected.name);
  return variabel;
};
export const decodeToken = () => {
  const token = localStorage.getItem('accessToken');
  if (token !== null) {
    return jwtDecode(token);
  }
  return false;
};
