import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/lib/storage';
import cartReducer from './cart';
import plansReducer from './plans';
import productsReducer from './products';
import settingsReducer from './settings';
import storeReducer from './store';
import userReducer from './user';
import agentReducer from './agent';
import langReducer from './language';

const userConfig = { key: 'user', storage, whitelist: ['KYCs'] };
const cartConfig = { key: 'cart', storage, whitelist: ['*'] };
const productsConfig = { key: 'products', storage, whitelist: [] };
const plansConfig = { key: 'plans', storage };
const storeConfig = { key: 'store', storage, blacklist: ['stores'] };
// const settingsConfig = { key: 'settings', storage };
const rootConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ['user', 'settings', 'cart'],
};
// const agentConfig = { key: 'agent', storage };

const user = persistReducer(userConfig, userReducer);
const store = persistReducer(storeConfig, storeReducer);
// const settings = persistReducer(settingsConfig, settingsReducer);
const cart = persistReducer(cartConfig, cartReducer);
const products = persistReducer(productsConfig, productsReducer);
const plans = persistReducer(plansConfig, plansReducer);
// const agents = persistReducer( agentConfig, agentReducer);

export default history =>
  persistReducer(
    rootConfig,
    combineReducers({
      user,
      store,
      settings: settingsReducer,
      router: connectRouter(history),
      products,
      plans,
      cart,
      agents: agentReducer,
      language: langReducer,
    })
  );
