import { DeleteTwoTone, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Drawer, List, Radio, Space, Tooltip } from 'antd';
import engKiny from 'constants/language';
// import { placeOrder } from 'container/actions/cart';
import { CHANGE_FREQUENCY } from 'container/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { calcRepayment, calcTenure, getLang, numberDelimiter, sumCart, useWindowSize } from 'utils';
import { path } from '../../../constants';
import styles from './style.module.scss';

const Cart = ({
  onClose,
  visible,
  title = 'Your Cart',
  onClick,
  onQuantityUpdate,
  onRemoveItem,
}) => {
  const { width } = useWindowSize();
  const [drawerWidth, setDrawerWidth] = useState(null);

  useEffect(() => {
    if (width < 550) setDrawerWidth('95%');
    else if (width >= 550 && width < 850) setDrawerWidth('60%');
    else setDrawerWidth('38%');
  }, [width]);

  const {
    cart: { frequency, items, loading },
    user: { score },
    plans: { selectedPlan },
  } = useSelector(state => state);

  const dispatch = useDispatch();

  // const { lang } = useSelector(state => state.language);

  const lang = getLang();
  const {
    Y_CART,
    PROCEED_CHECKOUT,
    SUBSCRIPTION_SUM,
    TENURE_P,
    QUALIFICATION_L,
    MONTH,
    MONTHLY_REP,
    SORRY_LIMIT,
    EACH_OF_OF_THEM,
    WEEKLY_REP,
    DAILY_REP,
    INCLUDES,
    MB,
    SMS,
    MINUTES_M,
    PLAN_H1_0,
  } = engKiny(lang);
  const { monthly, weekly, daily } = calcRepayment();
  const cartSum = sumCart();
  return (
    <Drawer
      headerStyle={{
        borderRadius: '100% / 0 0 50% 50%',
        backgroundColor: '#fc0',
        padding: 30,
        width: '100%',
        textAlign: 'center',
      }}
      title={Y_CART}
      onClose={onClose}
      visible={visible}
      width={drawerWidth}
    >
      <List
        className='card pl-3 pr-3'
        itemLayout='vertical'
        dataSource={items}
        renderItem={item => {
          const { specs, quantity } = item;
          const [first] = item.plans;
          const { bandleSms, bandleData, bandleMinutes, monthlyPayment } = first || {};
          return (
            <List.Item className='pl-3 mt-3'>
              <List.Item.Meta
                className='row'
                avatar={
                  <Avatar
                    style={{ width: '100%' }}
                    className='img-fluid'
                    shape='square'
                    size={80}
                    src={specs.photos[0]}
                  />
                }
                title={
                  <a href='#' onClick={() => onClick(item)}>
                    {specs.type_name}
                  </a>
                }
                description={specs.description?.slice(0, 80).padEnd(83, '.')}
              />
              <div className='row'>
                <h5>{`${quantity > 1 ? EACH_OF_OF_THEM : ''}${INCLUDES}:`}&nbsp;</h5>

                {lang === 'eng' &&
                  `${bandleSms} ${SMS} + ${bandleData} ${MB} + ${bandleMinutes} ${MINUTES_M} `}
                {lang === 'kiny' &&
                  ` ${SMS} ${bandleSms}+ ${MB} ${bandleData}  + ${MINUTES_M}  ${bandleMinutes}  `}
              </div>
              <div className={styles.qtyContainer}>
                <div className={styles.qtyContainer}>
                  <MinusCircleOutlined onClick={() => onQuantityUpdate(item, quantity - 1)} />
                  <span style={{ marginRight: 5, marginLeft: 5 }}>{quantity}</span>
                  <Tooltip
                    title={SORRY_LIMIT}
                    trigger={
                      monthly + monthlyPayment > score ||
                      selectedPlan?.name.toLowerCase() === 'personal'
                        ? 'click'
                        : ''
                    }
                    color='gold'
                    overlayInnerStyle={{ textAlign: 'center' }}
                  >
                    <PlusCircleOutlined
                      onClick={() => {
                        if (
                          monthly + monthlyPayment <= score &&
                          selectedPlan?.name.toLowerCase() !== 'personal'
                        )
                          onQuantityUpdate(item, quantity + 1);
                      }}
                    />
                  </Tooltip>
                </div>
                <DeleteTwoTone
                  size={90}
                  twoToneColor='#eb2f96'
                  onClick={() => onRemoveItem(item)}
                />
              </div>
            </List.Item>
          );
        }}
      />
      {items.length ? (
        <div className={styles.cartFooter}>
          <div
            className='border-bottom pt-4 pb-3 mb-3 font-weight-bold'
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
            }}
          >
            <h5 className='font-weight-bold' style={{ flex: 0.7 }}>
              {SUBSCRIPTION_SUM}
            </h5>
            <h5 className='font-weight-bold text-right' style={{ flex: 0.3 }}>
              {`${cartSum} Item${cartSum > 1 ? 's' : ''}`}
            </h5>
          </div>
          <h6>
            {QUALIFICATION_L} &nbsp;
            <span>
              {numberDelimiter(score)}&nbsp;{PLAN_H1_0}
            </span>
          </h6>
          <Radio.Group
            style={{ width: '100%' }}
            onChange={({ target }) => dispatch({ type: CHANGE_FREQUENCY, payload: target.value })}
            value={frequency}
          >
            <Space direction='vertical' style={{ width: '100%' }}>
              <div
                style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}
              >
                <Radio value='monthly'>
                  <h6>{MONTHLY_REP}&nbsp;</h6>
                </Radio>
                <h6 className='font-weight-bold'>
                  {numberDelimiter(monthly)}
                  &nbsp;RWF
                </h6>
              </div>
              <div
                style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}
              >
                <Radio value='weekly'>
                  <h6>{WEEKLY_REP}&nbsp;</h6>
                </Radio>
                <h6 className='font-weight-bold'>
                  {numberDelimiter(weekly)}
                  &nbsp;RWF
                </h6>
              </div>
              <div
                style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}
              >
                <Radio value='daily'>
                  <h6>{DAILY_REP}&nbsp;</h6>
                </Radio>
                <h6 className='font-weight-bold'>
                  {numberDelimiter(daily)}
                  &nbsp;RWF
                </h6>
              </div>
            </Space>
          </Radio.Group>
          <h6>
            {TENURE_P}&nbsp;
            <span>
              {calcTenure()}&nbsp;{MONTH}
            </span>
          </h6>
          <Button
            type='primary'
            size='large'
            className='text-center mt-4'
            loading={loading}
            // onClick={handleSumbit}
            // disabled
            style={{
              borderRadius: 5,
              paddingRight: 30,
              paddingLeft: 30,
              fontWeight: 'bold',
            }}
          >
            <Link to={path.KYC}>{PROCEED_CHECKOUT}</Link>
          </Button>
        </div>
      ) : null}
    </Drawer>
  );
};

export default Cart;
