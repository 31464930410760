import engKiny from 'constants/language';
import { GET_PLANS, LOAD_PLANS, PLANS_FAILED, SET_PLAN } from 'container/types';
import { getLang } from 'utils';

const lang = getLang();
const { PLAN_FAMILY, PLAN_PERSONAL, PLAN_H2_P, PLAN_H2_F } = engKiny(lang);
export const indicators = { loading: false };
const data = [
  {
    name: lang === 'eng' ? 'Personal' : 'Umuntu Ku giti cye',
    description:
      lang === 'eng'
        ? 'The personal plan allows you to get only one smartphone.'
        : "Gahunda y'umuntu ku giti cye igufasha kubona smartphone imwe gusa.",
  },
  {
    name: lang === 'eng' ? 'Family' : 'Umuryango',
    description:
      lang === 'eng'
        ? 'The family plan allows you to get more than one smartphone to be gifted to your friends and family.'
        : "Gahunda y'umuryango igufasha gutanga impano ku nshuti n'umuryango za smartphone irenze imwe",
  },
];

export const defaultPlans = { data, ...indicators, selectedPlan: undefined };

export default (state = defaultPlans, { type, payload }) => {
  switch (type) {
    case LOAD_PLANS:
      return { ...state, loading: true };
    case GET_PLANS:
      return { ...state, loading: false, data: payload };
    case SET_PLAN:
      return { ...state, loading: false, selectedPlan: payload };
    case PLANS_FAILED:
      return state;
    default:
      return state;
  }
};
