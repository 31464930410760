/* eslint-disable no-nested-ternary */
import { Layout } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

const AuthLayout = ({ children }) => {
  useSelector(({ router, settings }) => ({ router, settings }));
  return <Layout.Content style={{ backgroundColor: '#fc0' }}>{children}</Layout.Content>;
};

export default withRouter(AuthLayout);
