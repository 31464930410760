import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { logger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { history } from 'utils';
import rootReducer from './reducers';

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];
const { NODE_ENV } = process.env;
if (NODE_ENV === 'development') middlewares.push(logger);
const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: enhancers = compose } = window;

export const store = createStore(
  rootReducer(history),
  undefined,
  enhancers(applyMiddleware(...middlewares)),
);

export const persistor = persistStore(store);

export default { store, persistor };
