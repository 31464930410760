import 'antd/lib/style/index.less'; // antd core styles
import { persistor, store } from 'container';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { history } from 'utils';
import './components/vendors/antd/themes/dark.less'; // dark theme antd components
import './components/vendors/antd/themes/default.less'; // default theme antd components
import './global.scss'; // app & third-party component styles
import Localization from './localization';
import Router from './router';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}> 
      <Localization>
        <Router history={history} />
      </Localization>
    </PersistGate> 
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
