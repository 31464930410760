/* eslint-disable no-nested-ternary */
import { Col, Layout, Row } from 'antd';
import classNames from 'classnames';
import Cart from 'components/widgets/Cart';
import { CART_RESET, SET_SELECTED_ITEM, TOGGLE_CART } from 'container/types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { adjustCart, history, updateQuantity } from 'utils';
import Language from 'components/widgets/language/index';
import { path } from '../../constants';
import style from './style.module.scss';
import AgentNavBar from '../../pages/agents/components/NavBar';

const links = ['MTN Rwanda', 'Terms of Use', 'Privacy Policy', 'Compliance', 'Support', 'Contacts'];

const AuthLayout = ({ children }) => {
  const dispatch = useDispatch();

  const {
    settings: { isCardShadow, isSquaredBorders, isBorderless },
    plans: { selectedPlan: { name: planName = '' } = {} },
  } = useSelector(({ router, settings, cart, plans }) => ({ router, settings, cart, plans }));
  const { lang, landing } = useSelector(state => state.language);

  const { agent } = useSelector(store => store.agents);

  const { shouldShow } = useSelector(({ cart }) => cart);

  const { location } = history;

  const cartItemClick = item => {
    dispatch({ type: SET_SELECTED_ITEM, payload: item });
  };

  const onClose = () => {
    dispatch({ type: TOGGLE_CART, payload: { shouldShow: false } });
  };

  useEffect(() => {
    if (planName.toLowerCase() === 'personal') dispatch({ type: CART_RESET });
  }, [dispatch, planName]);

  return (
    <Layout.Content className={style.wrapper}>
      {/* The children wrapper */}
      <Row align='middle' justify='space-between' className={`${style.topbar}`}>
        <Col>
          <img src='/resources/images/mtn-logo-white.png' style={{ maxWidth: 90 }} alt='img' />
        </Col>
        {!agent && landing && (
          <>
            <Col>
              <Language />
            </Col>
          </>
        )}
        {agent && (
          <>
            <Col>
              <AgentNavBar />
            </Col>
          </>
        )}
      </Row>
      <div
        className={classNames(`${style.container}`, {
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
        style={{
          backgroundImage:
            location.pathname === path.HOMEPAGE && 'url(resources/images/landing.jpg)',
        }}
      >
        <Cart
          title='Your Cart'
          onClose={onClose}
          visible={shouldShow}
          onClick={cartItemClick}
          onRemoveItem={adjustCart}
          onQuantityUpdate={updateQuantity}
        />
        {children}
      </div>

      {/* The application main footer */}

      <div className={`mt-auto ${style.footer}`}>
        <ul className={`${style.footerNav} list-unstyled d-flex flex-wrap justify-content-center`}>
          {links.map((item, index) => {
            const strong = item.includes('MTN Rwanda');
            return (
              <li key={Number(index)} className='text-light'>
                {strong && `Copyright © ${new Date().getFullYear()}`}
                <a
                  href='https://www.mediatec.org/privacy'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-light'
                >
                  {strong ? <strong>MTN Rwanda</strong> : item}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </Layout.Content>
  );
};

export default withRouter(AuthLayout);
