import {
  BUNDLE_APPLY,
  BUNDLE_SUCCESS,
  CART_FAILED,
  CART_RESET,
  CHANGE_FREQUENCY,
  QTY_ADJUST,
  SET_SELECTED_ITEM,
  TOGGLE_CART,
  UPDATE_CART
} from 'container/types';

const defaultCart = {
  loading: false,
  items: [],
  selectedItem: null,
  shouldShow: false,
  frequency: 'monthly',
};

export default (state = defaultCart, { type, payload }) => {
  switch (type) {
    case UPDATE_CART:
      return { ...state, items: payload, shouldShow: true };
    case QTY_ADJUST:
      return { ...state, items: payload, shouldShow: true };
    case TOGGLE_CART:
      return { ...state, shouldShow: payload?.shouldShow || !state.shouldShow };
    case BUNDLE_APPLY:
      return { ...state, loading: true };
    case CHANGE_FREQUENCY:
      return { ...state, frequency: payload };
    case BUNDLE_SUCCESS:
      return { ...state, loading: false, shouldShow: false };
    case CART_FAILED:
      return { ...state, loading: false, shouldShow: false };
    case SET_SELECTED_ITEM:
      return { ...state, selectedItem: payload };
    case CART_RESET:
      return defaultCart;
    default:
      return state;
  }
};
