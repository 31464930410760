import { LOAD_SETTING } from 'container/types';

const defaultSettings = {
  authProvider: 'jwt', // google, jwt, microsoft, facebook
  logo: 'Selfcare',
  locale: 'en-US',
  isSidebarOpen: true,
  isSupportChatOpen: false,
  isMobileView: false,
  isMobileMenuOpen: false,
  isMenuCollapsed: false,
  menuLayoutType: 'left', // left, top, nomenu
  routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
  menuColor: 'white', // white, dark, gray
  theme: 'default', // default, dark
  authPagesColor: 'gray', // white, gray, image
  primaryColor: '#fc0',
  leftMenuWidth: 256,
  isMenuUnfixed: false,
  isMenuShadow: false,
  isTopbarFixed: true,
  isGrayTopbar: false,
  isContentMaxWidth: false,
  isAppMaxWidth: false,
  isGrayBackground: true,
  isCardShadow: true,
  isSquaredBorders: false,
  isBorderless: false,
};

export default (state = defaultSettings, { type, payload }) => {
  if (type === LOAD_SETTING) return { ...state, ...payload };
  return state;
};
