import ChangeLang from 'container/actions/language';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setLang } from 'utils';

const Language = () => {
  const dispatch = useDispatch();
  return (
    <select
      className=' w-full h-[14px] outline-none border border-none px-3 py-2  rounded-lg text-slate-900 text-lg font-bold font-serif'
      onChange={e => {
        const lang = e.target.value;
        setLang(lang);
        dispatch(ChangeLang(lang));
      }}
    >
      <option value='kiny'>Select Language</option>
      <option value='eng'>English</option>
      <option value='kiny'>Kinyarwanda</option>
    </select>
  );
};

export default Language;
