import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Button, Col, Row } from 'antd';


const AgentNavBar = () => {
  const { agent } = useSelector((store) => store.agents);
  
  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('agentUser');
    window.location = '/support/auth';
  }

  // props
  return (
    <Fragment>
      <Row justify="space-between" align="middle" style={{padding: 6,}}>
        <Col>
          <Avatar size={64} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{ `${agent.name}` }</Avatar>
        </Col>
        <Col style={{paddingLeft: 10}}>
          <Button onClick={logout} size="medium">Logout</Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AgentNavBar;