import { GET_LOCATION, GET_STORES, LOAD_STORES, STORE_FAILED } from 'container/types';

const defaultLocs = {
  provinces: [],
  districts: [],
  sectors: [],
  cells: [],
  villages: [],
  setLocation: []
};

const defaultStore = {
  loading: false,
  stores: [],
  ...defaultLocs
};

export default (state = defaultStore, { type, payload }) => {
  switch (type) {
    case LOAD_STORES:
      return { ...state, loading: true };
    case GET_STORES:
      return { ...state, stores: payload, loading: false };
    case GET_LOCATION:
      return { ...state, ...payload, loading: false };
    case STORE_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
