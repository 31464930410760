import {
  LOAD_USER,
  LOGOUT,
  UPDATE_KYC,
  UPDATE_PROFILE,
  USER_FAILED,
  UPDATE_PRINCIPLE,
  VERIFY_MSISDN,
  VERIFY_OTP_LOADING,
  VERIFY_OTP,
  SET_ADDRESS_IDS,
  CLEAR_ADDRESS_IDS,
  FIND_DEPENDANT,
  CLEAR_OLD_CUSTOMER
} from 'container/types';

const score = 50000,
  tenure = 12;

export const indicators = { otpSent: false, loading: false };

export const defaultProfile = {
  id: null,
  fullName: null,
  email: null,
  msisdn: null,
  avatar: null,
  country: null,
  nationalId: null,
};

export const defaultUser = {
  profile: defaultProfile,
  ...indicators,
  score,
  tenure,
  KYCs: [],
  isDependant:false,
  addressIds:{},
};

export default (state = defaultUser, { type, payload }) => {
  switch (type) {
    case LOAD_USER:
      return { ...state, loading: true };
    case VERIFY_MSISDN:
      return { ...state, loading: false, otpSent: true };
    case VERIFY_OTP: {
      const { user: profile } = payload;
      return { ...state, loading: true, otpSent: false, profile };
    }
    case VERIFY_OTP_LOADING:{
      return { ...state, loading: false, otpSent: false };
    }
    case UPDATE_PROFILE: {
      const { profile, customer, principal, ...rest } = payload;
      return { ...state, customer: { ...state.customer, ...customer },principal, ...rest };
    }
    case UPDATE_PRINCIPLE:{
      return { ...state, principal: payload};
    }
    case CLEAR_OLD_CUSTOMER:{
      return { ...state, customer: { }};
    }
    case FIND_DEPENDANT:{
      return {...state, customer:{...state.customer, ...payload},loading: false}
    }
    case SET_ADDRESS_IDS:{
      return {...state, addressIds:{...state.addressIds,...payload}} 
    }
    case CLEAR_ADDRESS_IDS:{
      return {...state, addressIds:{}} 
    }
    case UPDATE_KYC:{
      const { KYCs, profile } = state;
      const { customer, index = 0 } = payload;
      if (!index) customer.msisdn = profile.msisdn;
      KYCs.splice(index, 1, { ...KYCs[index], ...customer });
      return { ...state, KYCs };
    }
    case LOGOUT:
      return defaultUser;
    case USER_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
