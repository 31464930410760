import { SELF_LANGUAGE, LANDING_LANGUAGE } from 'container/types';
import { getLang } from 'utils';

export const initState = {
  lang: 'kiny',
  landing: false,
};
export default (state = initState, { type, paylod }) => {
  switch (type) {
    case SELF_LANGUAGE:
      return { ...state, lang: paylod };
    case LANDING_LANGUAGE:
      return { ...state, landing: true };
    default:
      return state;
  }
};
