const kiny = require('../lang/kiny.json');
const eng = require('../lang/eng.json');

const engKiny = lang => {
  return {
    GET_STARTED: lang === 'eng' ? eng.get_started : lang === 'kiny' && kiny.get_started,
    WELCOME_TITLE: lang === 'eng' ? eng.welcome_t1 : lang === 'kiny' && kiny.welcome_t1,
    WELCOME_DETAILS: lang === 'eng' ? eng.welcome_t2 : lang === 'kiny' && kiny.welcome_t2,
    LOGIN_WELCOME: lang === 'eng' ? eng.login_welcome : lang === 'kiny' && kiny.login_welcome,
    FORM_LOGIN_TITLE:
      lang === 'eng' ? eng.form_login_title : lang === 'kiny' && kiny.form_login_title,
    FORM_LOGIN_PHONE:
      lang === 'eng' ? eng.form_login_phone : lang === 'kiny' && kiny.form_login_phone,
    LOGIN_BTN: lang === 'eng' ? eng.login_btn : lang === 'kiny' && kiny.login_btn,
    LOGIN_BACK_BTN: lang === 'eng' ? eng.login_back_btn : lang === 'kiny' && kiny.login_back_btn,
    FORM_LOGIN_PIN: lang === 'eng' ? eng.form_login_pin : lang === 'kiny' && kiny.form_login_pin,
    INVALID_PHONE: lang === 'eng' ? eng.invalid_phone : lang === 'kiny' && kiny.invalid_phone,
    SIGN_IN_BTN: lang === 'eng' ? eng.sign_ing_btn : lang === 'kiny' && kiny.sign_ing_btn,
    RESEND_BTN: lang === 'eng' ? eng.resend_btn : lang === 'kiny' && kiny.resend_btn,
    RECEIVE_OTP_LABEL:
      lang === 'eng' ? eng.recieve_otp_label : lang === 'kiny' && kiny.recieve_otp_label,
    WAIT_LABEL: lang === 'eng' ? eng.wait_label : lang === 'kiny' && kiny.wait_label,
    OTP_LABEL_VALIDITY: lang === 'eng' ? eng.validate_otp : lang === 'kiny' && kiny.validate_otp,
    MODAL_ALERT_1: lang === 'eng' ? eng.modal_alert_1 : lang === 'kiny' && kiny.modal_alert_1,
    MODAL_OK: lang === 'eng' ? eng.modal_ok : lang === 'kiny' && kiny.modal_ok,
    MODAL_CANCEL: lang === 'eng' ? eng.modal_cancel : lang === 'kiny' && kiny.modal_cancel,
    OTP_SUCCESS: lang === 'eng' ? eng.otp_success : lang === 'kiny' && kiny.otp_success,
    OTP_SENT: lang === 'eng' ? eng.otp_sent : lang === 'kiny' && kiny.otp_sent,
    CHOOSE_PLAN: lang === 'eng' ? eng.choose_pl : lang === 'kiny' && kiny.choose_pl,
    DEAR: lang === 'eng' ? eng.dear : lang === 'kiny' && kiny.dear,
    PLAN_H1: lang === 'eng' ? eng.plan_h1 : lang === 'kiny' && kiny.plan_h1,
    PLAN_H1_0: lang === 'eng' ? eng.plan_h1_0 : lang === 'kiny' && kiny.plan_h1_0,
    PLAN_H1_1: lang === 'eng' ? eng.plan_h1_1 : lang === 'kiny' && kiny.plan_h1_1,
    PLAN_H1_2: lang === 'eng' ? eng.plan_h1_2 : lang === 'kiny' && kiny.plan_h1_2,
    PLAN_PERSONAL: lang === 'eng' ? eng.personal : lang === 'kiny' && kiny.personal,
    PLAN_FAMILY: lang === 'eng' ? eng.family : lang === 'kiny' && kiny.family,
    PLAN_H2_P: lang === 'eng' ? eng.plan_h2_p : lang === 'kiny' && kiny.plan_h2_p,
    PLAN_H2_F: lang === 'eng' ? eng.plan_h2_f : lang === 'kiny' && kiny.plan_h2_f,
    PACKAGE_c: lang === 'eng' ? eng.package_c : lang === 'kiny' && kiny.package_c,
    SMS: lang === 'eng' ? eng.sms : lang === 'kiny' && kiny.sms,
    MB: lang === 'eng' ? eng.mb : lang === 'kiny' && kiny.mb,
    MINUTES_M: lang === 'eng' ? eng.minutes_m : lang === 'kiny' && kiny.minutes_m,
    INSUR_COVER_1:
      lang === 'eng' ? eng.insurance_cover_1 : lang === 'kiny' && kiny.insurance_cover_1,
    INSUR_COVER_2:
      lang === 'eng' ? eng.insurance_cover_2 : lang === 'kiny' && kiny.insurance_cover_2,
    INSUR_COVER_3:
      lang === 'eng' ? eng.insurance_cover_3 : lang === 'kiny' && kiny.insurance_cover_3,
    REPAYMENT_1: lang === 'eng' ? eng.repayment_1 : lang === 'kiny' && kiny.repayment_1,
    TENURE_P: lang === 'eng' ? eng.tenure_p : lang === 'kiny' && kiny.tenure_p,
    MONTH: lang === 'eng' ? eng.month : lang === 'kiny' && kiny.month,
    P_PL: lang === 'eng' ? eng.p_pl : lang === 'kiny' && kiny.p_pl,
    CATEGORIES_P1: lang === 'eng' ? eng.categories_p1 : lang === 'kiny' && kiny.categories_p1,
    FORM_SELECT_LANG: lang === 'eng' ? eng.sl_lang : lang === 'kiny' && kiny.sl_lang,
    LANG_KINY: lang === 'eng' ? eng.kin : lang === 'kiny' && kiny.kin,
    LANG_ENG: lang === 'eng' ? eng.eng : lang === 'kiny' && kiny.eng,
    ADD_CART: lang === 'eng' ? eng.add_cart : lang === 'kiny' && kiny.add_cart,
    M_BUNDLE: lang === 'eng' ? eng.m_bundle : lang === 'kiny' && kiny.m_bundle,
    INSURA_DESC: lang === 'eng' ? eng.insurance_desc : lang === 'kiny' && kiny.insurance_desc,
    INSURA_DESC1: lang === 'eng' ? eng.insurance_desc1 : lang === 'kiny' && kiny.insurance_desc1,
    INSURA_DESC2: lang === 'eng' ? eng.insurance_desc2 : lang === 'kiny' && kiny.insurance_desc2,
    INSURA_DETAIL: lang === 'eng' ? eng.insurance_detail : lang === 'kiny' && kiny.insurance_detail,
    INSURA_DETAIL1:
      lang === 'eng' ? eng.insurance_detail1 : lang === 'kiny' && kiny.insurance_detail1,
    CHIPSET: lang === 'eng' ? eng.chipset : lang === 'kiny' && kiny.chipset,
    ANDROID_V: lang === 'eng' ? eng.android_v : lang === 'kiny' && kiny.android_v,
    NETWORK: lang === 'eng' ? eng.network : lang === 'kiny' && kiny.network,
    DISPLAY: lang === 'eng' ? eng.display : lang === 'kiny' && kiny.display,
    CAMERA: lang === 'eng' ? eng.camera : lang === 'kiny' && kiny.camera,
    BATTERY: lang === 'eng' ? eng.battery : lang === 'kiny' && kiny.battery,
    MONTHLY_REP: lang === 'eng' ? eng.monthly_rep : lang === 'kiny' && kiny.monthly_rep,
    Y_CART: lang === 'eng' ? eng.y_cart : lang === 'kiny' && kiny.y_cart,
    INCLUDES: lang === 'eng' ? eng.includes : lang === 'kiny' && kiny.includes,
    SUBSCRIPTION_SUM:
      lang === 'eng' ? eng.subscription_sum : lang === 'kiny' && kiny.subscription_sum,
    ITEM: lang === 'eng' ? eng.item : lang === 'kiny' && kiny.item,
    QUALIFICATION_L:
      lang === 'eng' ? eng.qualification_limit : lang === 'kiny' && kiny.qualification_limit,
    WEEKLY_REP: lang === 'eng' ? eng.weekly_rep : lang === 'kiny' && kiny.weekly_rep,
    DAILY_REP: lang === 'eng' ? eng.daily_rep : lang === 'kiny' && kiny.daily_rep,
    PROCEED_CHECK: lang === 'eng' ? eng.proceed_check : lang === 'kiny' && kiny.proceed_check,
    KYC_TITLE: lang === 'eng' ? eng.kyc_title : lang === 'kiny' && kiny.kyc_title,
    KYC_U_P_P: lang === 'eng' ? eng.kyc_u_p_p : lang === 'kiny' && kiny.kyc_u_p_p,
    KYC_U_I_P: lang === 'eng' ? eng.kyc_u_i_p : lang === 'kiny' && kiny.kyc_u_i_p,
    KYC_FNAME: lang === 'eng' ? eng.kyc_fname : lang === 'kiny' && kiny.kyc_fname,
    KYC_N_NAME: lang === 'eng' ? eng.kyc_n_name : lang === 'kiny' && kiny.kyc_n_name,
    KYC_LNAME: lang === 'eng' ? eng.kyc_lname : lang === 'kiny' && kiny.kyc_lname,
    KYC_NID: lang === 'eng' ? eng.kyc_Nid : lang === 'kiny' && kiny.kyc_Nid,
    KYC_ASSIGN_DVC:
      lang === 'eng' ? eng.kyc_assign_device : lang === 'kiny' && kiny.kyc_assign_device,
    KYC_PROVINCE: lang === 'eng' ? eng.kyc_province : lang === 'kiny' && kiny.kyc_province,
    KYC_DISTRICT: lang === 'eng' ? eng.kyc_district : lang === 'kiny' && kiny.kyc_district,
    KYC_SECTOR: lang === 'eng' ? eng.kyc_sector : lang === 'kiny' && kiny.kyc_sector,
    KYC_CELL: lang === 'eng' ? eng.kyc_cell : lang === 'kiny' && kiny.kyc_cell,
    KYC_VILLAGE: lang === 'eng' ? eng.kyc_village : lang === 'kiny' && kiny.kyc_village,
    KYC_BTN_CONTINUE:
      lang === 'eng' ? eng.kyc_btn_continue : lang === 'kiny' && kiny.kyc_btn_continue,
    KYC_BTN_SUBMIT: lang === 'eng' ? eng.kyc_btn_submit : lang === 'kiny' && kiny.kyc_btn_submit,
    KYC_FULLNAME: lang === 'eng' ? eng.kyc_fullname : lang === 'kiny' && kiny.kyc_fullname,
    KYC_DEVICE: lang === 'eng' ? eng.kyc_device : lang === 'kiny' && kiny.kyc_device,
    TC_CONFIRMATION:
      lang === 'eng' ? eng.tc_confiramation : lang === 'kiny' && kiny.tc_confiramation,
    TC_CONFIRM: lang === 'eng' ? eng.tc_confirm : lang === 'kiny' && kiny.tc_confirm,
    TC_CONFIRM_DTL: lang === 'eng' ? eng.tc_confirm_dtl : lang === 'kiny' && kiny.tc_confirm_dtl,
    BTN_AGREE: lang === 'eng' ? eng.btn_agree : lang === 'kiny' && kiny.btn_agree,
    BTN_REJECT: lang === 'eng' ? eng.btn_reject : lang === 'kiny' && kiny.btn_reject,
    TITLE: lang === 'eng' ? eng.title : lang === 'kiny' && kiny.title,
    CONFIRM_PARAGRAPH:
      lang === 'eng' ? eng.confirm_paragraph : lang === 'kiny' && kiny.confirm_paragraph,
    CONFIRM_PARAGRAPH_1:
      lang === 'eng' ? eng.confirm_paragraph_1 : lang === 'kiny' && kiny.confirm_paragraph_1,
    CANCEL: lang === 'eng' ? eng.cancel : lang === 'kiny' && kiny.cancel,
    BTN_ORDER: lang === 'eng' ? eng.btn_order : lang === 'kiny' && kiny.btn_order,
    KYC_PHONE: lang === 'eng' ? eng.kyc_phone : lang === 'kiny' && kiny.kyc_phone,
    ENTER_VALID: lang === 'eng' ? eng.enter_valid : lang === 'kiny' && kiny.enter_valid,
    KYC_B_INFO: lang === 'eng' ? eng.kyc_b_info : lang === 'kiny' && kiny.kyc_b_info,
    KYC_I_INFO: lang === 'eng' ? eng.kyc_i_info : lang === 'kiny' && kiny.kyc_i_info,
    KYC_OUR: lang === 'eng' ? eng.kyc_our : lang === 'kiny' && kiny.kyc_our,
    KYC_ENTER_F: lang === 'eng' ? eng.kyc_enter_f : lang === 'kiny' && kiny.kyc_enter_f,
    INVALID_NICK_NAME:
      lang === 'eng' ? eng.invalid_nick_name : lang === 'kiny' && kiny.invalid_nick_name,
    INVALID_LNAME: lang === 'eng' ? eng.invalid_lname : lang === 'kiny' && kiny.invalid_lname,
    INVALID_DEVICE: lang === 'eng' ? eng.device_required : lang === 'kiny' && kiny.device_required,
    KYC_INFO: lang === 'eng' ? eng.info : lang === 'kiny' && kiny.info,
    KYC_BENS: lang === 'eng' ? eng.benefs : lang === 'kiny' && kiny.benefs,
    KYC_FULL_NAME: lang === 'eng' ? eng.full_name : lang === 'kiny' && kiny.full_name,
    PERSONAL_PLAN: lang === 'eng' ? eng.personal_plan : lang === 'kiny' && kiny.personal_plan,
    FAMILY_PLAN: lang === 'eng' ? eng.family_plan : lang === 'kiny' && kiny.family_plan,
    BLAND: lang === 'eng' ? eng.brand : lang === 'kiny' && kiny.brand,
    LOADING: lang === 'eng' ? eng.loading : lang === 'kiny' && kiny.loading,
    CART_REMOVE: lang === 'eng' ? eng.remove_cart : lang === 'kiny' && kiny.remove_cart,
    REJECT_ERROR: lang === 'eng' ? eng.reject_error : lang === 'kiny' && kiny.reject_error,
    NETWORK_ERROR: lang === 'eng' ? eng.network_error : lang === 'kiny' && kiny.network_error,
    UNKNOWN_ERROR: lang === 'eng' ? eng.unkonwn_error : lang === 'kiny' && kiny.unkonwn_error,
    MY_ERROR: lang === 'eng' ? eng.my_error : lang === 'kiny' && kiny.my_error,
    INTERNAL_ERROR: lang === 'eng' ? eng.internal_error : lang === 'kiny' && kiny.internal_error,
    OFFER_SUCCESS: lang === 'eng' ? eng.offer_success : lang === 'kiny' && kiny.offer_success,
    CALL_SUPPORT: lang === 'eng' ? eng.call_support : lang === 'kiny' && kiny.call_support,
    USSD_MY_ERROR:
      lang === 'eng' ? eng.ussd_my_new_error : lang === 'kiny' && kiny.ussd_my_new_error,
    PROCEED_CHECKOUT: lang === 'eng' ? eng.proceed_check : lang === 'kiny' && kiny.proceed_check,
    FAILED: lang === 'eng' ? eng.failed : lang === 'kiny' && kiny.failed,
    OTP_SENT_TT: lang === 'eng' ? eng.otp_sent_tt : lang === 'kiny' && kiny.otp_sent_tt,
    EACH_OF_OF_THEM: lang === 'eng' ? eng.each_of_them : lang === 'kiny' && kiny.each_of_them,
    SORRY_LIMIT: lang === 'eng' ? eng.sorry_limit : lang === 'kiny' && kiny.sorry_limit,

    ELIGIBLITY_TITLE:
      lang === 'eng' ? eng.eligibility_title : lang === 'kiny' && kiny.eligibility_title,
    ELIGIBILITY_PARAGRAPH:
      lang === 'eng' ? eng.eligibility_paragraph : lang === 'kiny' && kiny.eligibility_paragraph,

    PROVINCE_N: lang === 'eng' ? eng.province_n : lang === 'kiny' && kiny.province_n,
    PROVINCE_K: lang === 'eng' ? eng.province_k : lang === 'kiny' && kiny.province_k,
    PROVINCE_W: lang === 'eng' ? eng.province_w : lang === 'kiny' && kiny.province_w,
    PROVINCE_S: lang === 'eng' ? eng.province_s : lang === 'kiny' && kiny.province_s,
    PROVINCE_E: lang === 'eng' ? eng.province_e : lang === 'kiny' && kiny.province_e,
    OTP_ERROR: lang === 'eng' ? eng.otp_error : lang === 'kiny' && kiny.otp_error,
    OTP_ERROR_DESC: lang === 'eng' ? eng.otp_error_desc : lang === 'kiny' && kiny.otp_error_desc,
    APPLIED_t: lang === 'eng' ? eng.already_applied_T : lang === 'kiny' && kiny.already_applied_T,
    APPLIED_p: lang === 'eng' ? eng.already_applied_P : lang === 'kiny' && kiny.already_applied_P,
    STORE_T1:lang === 'eng' ? eng.store_T1 : lang === 'kiny' && kiny.store_T1,
    STORE_T2:lang === 'eng' ? eng.store_T2 : lang === 'kiny' && kiny.store_T2,
    STORE_T3:lang === 'eng' ? eng.store_T3 : lang === 'kiny' && kiny.store_T3,
    STORE_T4:lang === 'eng' ? eng.store_T4 : lang === 'kiny' && kiny.store_T4,
    STORE_T5:lang === 'eng' ? eng.store_T5 : lang === 'kiny' && kiny.store_T5,
    STORE_T6:lang === 'eng' ? eng.store_T6 : lang === 'kiny' && kiny.store_T6,
    STORE_T7:lang === 'eng' ? eng.store_T7 : lang === 'kiny' && kiny.store_T7,
    STORE_T8:lang === 'eng' ? eng.store_T8 : lang === 'kiny' && kiny.store_T8,
    ACTIVE_LOAN:lang === 'eng' ? eng.active_loan : lang === 'kiny' && kiny.active_loan,
    SETP_ERROR:lang === 'eng' ? eng.step_error : lang === 'kiny' && kiny.step_error,
    MTN_ELIGIBILITY:lang === 'eng' ? eng.mtn_eligibility : lang === 'kiny' && kiny.mtn_eligibility,
    BANK_ELIGIBILITY:lang === 'eng' ? eng.bank_eligibility : lang === 'kiny' && kiny.bank_eligibility,
    SEARCH:lang === 'eng' ? eng.search : lang === 'kiny' && kiny.search
  };
};
export default engKiny;
