// eslint-disable-next-line camelcase
export const { REACT_APP_BASEURL = 'https://intelligrapi.intlgr3.tech/intelligrapi' } = process.env;
export const { REACT_APP_INTELLIGRA_USERNAME, REACT_APP_INTELLIGRA_PASSWORD } = process.env;
export const { REACT_APP_SMSAPI_BASEURL } = process.env;
export const smsAPI = REACT_APP_SMSAPI_BASEURL;
export const path = {
  // Public
  HOMEPAGE: '/',
  // Account
  CATALOG: '/bundles/catalog',
  PLANS: '/bundles/plans',
  DETAILS: '/bundles/details',
  STORES: '/bundles/stores',
  KYC: '/customer/information',
  TERMS: '/bundles/terms&conditions',
  // Authentication
  LOGIN: '/auth/login',
  // Agents
  LOGIN_AGENT: '/support/auth',
  SUPPORT_AGENT: '/support',
  DASHBOARD_AGENT: '/support/agent/dashboard',
  AGENT_USER_CREATE: '/support/agent/user-create',
  AGENT_USERS: '/support/agent/users',
  //
  404: '/auth/404',
  // Agents
  SCHEDULE: '/repayments/schedule',
};

export const validators = {
  phoneNumber: /^(\+?25)?07(8|9)(\d{7})$/,
  nationalId: /^[0-9]{16}$/,
};
