export const LOAD_MENU = 'LOAD_MENU';
export const GET_MENU = 'GET_MENU';

// SETTINGS
export const LOAD_SETTING = 'LOAD_SETTING';
export const SET_PRI_COLOR = 'SET_PRI_COLOR';
export const SET_THEME = 'SET_THEME';
export const CHANGE_SETTING = 'CHANGE_SETTING';

// USER
export const LOAD_USER = 'LOAD_USER';
export const FIND_DEPENDANT='FIND_DEPENDANT';
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const VERIFY_MSISDN = 'VERIFY_MSISDN';
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_LOADING='VERIFY_OTP_LOADING';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const USER_FAILED = 'USER_FAILED';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_OLD_CUSTOMER='CLEAR_OLD_CUSTOMER'
export const UPDATE_KYC = 'UPDATE_KYC';
export const SET_ADDRESS_IDS='SET_ADDRESS_IDS';
export const CLEAR_ADDRESS_IDS='CLEAR_ADDRESS_IDS';

// PRODUCTS
export const UPDATE_PRINCIPLE='UPDATE_PRINCIPLE';
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const PRODUCTS_FAILED = 'PRODUCTS_FAILED';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const PROD_CLEAR_INDS = 'PROD_CLEAR_INDS';

// PLANS

export const LOAD_PLANS = 'LOAD_PLANS';
export const GET_PLANS = 'GET_PLANS';
export const PLANS_FAILED = 'PLANS_FAILED';
export const SET_PLAN = 'SET_PLAN';

// CART

export const UPDATE_CART = 'UPDATE_CART';
export const CART_FAILED = 'CART_FAILED';
export const QTY_ADJUST = 'QTY_ADJUST';
export const BUNDLE_APPLY = 'BUNDLE_APPLY';
export const BUNDLE_SUCCESS = 'BUNDLE_SUCCESS';
export const CART_RESET = 'CART_RESET';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const TOGGLE_CART = 'TOGGLE_CART';
export const CHANGE_FREQUENCY = 'CHANGE_FREQUENCY';

// STORES

export const LOAD_STORES = 'LOAD_STORES';
export const GET_STORES = 'GET_STORES';
export const STORE_FAILED = 'STORE_FAILED';
export const GET_LOCATION = 'GET_LOCATION';

export const CHANGE_PROVINCE = 'provinces';
export const CHANGE_DISTRICT = 'districts';
export const CHANGE_SECTOR = 'sectors';
export const CHANGE_CELL = 'cells';
export const CHANGE_VILLAGE = 'villages';

export const NORTH_CODE = 'OL05000020';
export const CITY_CODE = 'OL05000005';
export const SOUTH_CODE = 'OL05000010';
export const EST_CODE = 'OL05000025';
export const WEST_CODE = 'OL05000015';

export const AGENT_LOGIN = 'AGENT_LOGIN';
export const AGENT_CLIENT_DETAILS = 'AGENT_CLIENT_DETAILS';
export const AGENT_CLIENT_LOANS = 'AGENT_CLIENT_LOANS';
export const AGENT_CLIENT_ERROR = 'AGENT_CLIENT_ERROR';
export const AGENT_LOGOUT = 'AGENT_LOGOUT';
export const AGENT_LOADING = 'AGENT_LOADING';
export const AGENT_CLIENT_PROFILE_LOADING = 'AGENT_CLIENT_PROFILE_LOADING';
export const AGENT_CLIENT_PROFILE = 'AGENT_CLIENT_PROFILE';

export const AGENT_GET_ALL_USERS = 'AGENT_GET_ALL_USERS';
export const AGENT_CREATE_USERS = 'AGENT_CREATE_USERS';
export const AGENT_EXTEND_DEVICE = 'AGENT_EXTEND_DEVICE';
export const AGENT_GET_COMMENTS = 'AGENT_GET_COMMENTS';
export const AGENT_POST_COMMENT = 'AGENT_POST_COMMENT';
export const SELF_LANGUAGE = 'SELF_CARE_LANGUAGE';
export const LANDING_LANGUAGE = 'SET_LANDING_LANGUAGE';
